<section class="sidebar">
  <div (click)="isConnected()" class="wallet-button block flex-center">
    <video class="wallet" loop muted autoplay playsinline oncanplay="this.play()" onloadedmetadata="this.muted = true"
      poster="./assets/media/videos/animated-wallet-icon.gif">
      <source src="./assets/media/videos/animated-wallet-icon.webm" type="video/webm">
    </video>
  </div>
  <ul>
    <li>
      <a href="https://rbits.xyz" target="_blank">RBITS</a>
    </li>
    <li>
      <p (click)="toggleTokenomicsView()">Tokenomics</p>
    </li>
  </ul>
</section>

<section class="tokenomics" *ngIf="active">
  <app-tokenomics></app-tokenomics>
</section>
