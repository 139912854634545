<section
  class="artist-moonbox scroller"
  [ngClass]="{'fadeout':fadeOut,'fade-in':popupClosed && !fadeOut}"
>
  <div
    *ngIf="!supplyDetails && !pageLoader"
    class="flex-center main-message-container w-100 h-100 text-center pa20"
  >
    {{ mainMessage }}
    <img
      *ngIf="mainMessage === messages.IDLE"
      class="flex"
      src="./assets/media/videos/Loading.gif"
      alt="loading"
    />
  </div>

  <div
    *ngIf="supplyDetails && pageLoader"
    class="inner s-100 justify-content-evenly flex-col pt-md-100"
    [ngClass]="{'fadeout':fadeOut,'fade-in':popupClosed && !fadeOut}"
  >
    <div class="flex w-100">
      <div
        class="flex flex-col w-100 boxes-title-container interactable"
        style="margin: 0 auto; cursor: pointer; width: fit-content;"
        (click)="viewDetails()"
      >
        <div class="artist-details flex-center flex-col">
          <p
            class="p-white-span-primary p30d24m orbitron bold mb10 text-center"
          >
            {{ artistDetails?.name }}
          </p>
          <p class="p18 orbitron mb10 text-center">
            {{ artistDetails?.ArtistName }}
          </p>
          <svg
            class="info-icon wrapped"
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            viewBox="0 0 38 38"
            xml:space="preserve"
          >
            <g fill="#5e2466">
              <path
                d="M19 9.2c-1.3 0-2.4 1.1-2.4 2.4S17.7 14 19 14s2.4-1.1 2.4-2.4-1.1-2.4-2.4-2.4zM19 16.6c-1.1 0-2 .9-2 2v8.3c0 1.1.9 2 2 2s2-.9 2-2v-8.3c0-1.2-.9-2-2-2z"
              />
              <path
                d="M19 1C9.1 1 1 9.1 1 19s8.1 18 18 18 18-8.1 18-18S28.9 1 19 1zm-.1 33.1c-3.8 0-7.6-1.4-10.5-4.3-2.9-2.8-4.5-6.6-4.5-10.6s1.5-7.8 4.3-10.7l.2-.2c5.8-5.9 15.3-6 21.3-.2 2.9 2.8 4.5 6.6 4.5 10.6s-1.5 7.8-4.3 10.7l-.2.2c-3 3-6.9 4.5-10.8 4.5z"
              />
              <path
                d="M19 1C9.1 1 1 9.1 1 19s8.1 18 18 18 18-8.1 18-18S28.9 1 19 1zm-.1 33.1c-3.8 0-7.6-1.4-10.5-4.3-2.9-2.8-4.5-6.6-4.5-10.6s1.5-7.8 4.3-10.7l.2-.2c5.8-5.9 15.3-6 21.3-.2 2.9 2.8 4.5 6.6 4.5 10.6s-1.5 7.8-4.3 10.7l-.2.2c-3 3-6.9 4.5-10.8 4.5z"
              />
            </g>
          </svg>
        </div>
      </div>
    </div>

    <div class="all-boxes flex w-100">
      <div
        *ngFor="let box of boxTypes; let i = index; trackBy: trackByFn;"
        class="moonbox-container flex1 flex-center"
        [class.selected]="current === i"
      >
        <div class="round-mini-button" (click)="next(-1)">
          <mat-icon fontSet="material-icons" class="color-primary"
            >arrow_back</mat-icon
          >
        </div>
        <div class="content flex-center flex-col">
          <div class="relative">
            <div
              class="relative moonbox cursor-pointer"
              [style.background-image]="
              box === 'wood' ? 'url(\'' + assetBaseUrl + '/media/sprites/moonboxes/wood.webp\')' :
              box === 'silver' ? 'url(\'' + assetBaseUrl + '/media/sprites/moonboxes/silver.webp\')' :
              box === 'gold' ? 'url(\'' + assetBaseUrl + '/media/sprites/moonboxes/gold.webp\')' :
              box === 'diamond' ? 'url(\'' + assetBaseUrl + '/media/sprites/moonboxes/diamond.webp\')' : ''"
              [class.disabled]="!hasEnoughRBITS(i) || supply[i] === 0"
              (mouseover)="infoHoverList[i].isHovered = true"
              (mouseleave)="infoHoverList[i].isHovered = false"
            ></div>
            <img
              *ngIf="!hasEnoughRBITS(i) && !infoHoverList[i].isHovered"
              (mouseover)="infoHoverList[i].isHovered = true"
              src="./assets/media/images/moonbox/Lock_moonbox.svg"
              alt=""
              class="lock only-desktop"
            />
            <svg
              *ngIf="!hasEnoughRBITS(i) && infoHoverList[i].isHovered"
              [routerLink]="['/info']"
              routerLinkActive="router-link-active"
              (mouseover)="infoHoverList[i].isHovered = true"
              class="info-icon lock cursor-pointer only-desktop"
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              viewBox="0 0 38 38"
              xml:space="preserve"
            >
              <g fill="#FFFFFF">
                <path
                  d="M19 9.2c-1.3 0-2.4 1.1-2.4 2.4S17.7 14 19 14s2.4-1.1 2.4-2.4-1.1-2.4-2.4-2.4zM19 16.6c-1.1 0-2 .9-2 2v8.3c0 1.1.9 2 2 2s2-.9 2-2v-8.3c0-1.2-.9-2-2-2z"
                />
                <path
                  d="M19 1C9.1 1 1 9.1 1 19s8.1 18 18 18 18-8.1 18-18S28.9 1 19 1zm-.1 33.1c-3.8 0-7.6-1.4-10.5-4.3-2.9-2.8-4.5-6.6-4.5-10.6s1.5-7.8 4.3-10.7l.2-.2c5.8-5.9 15.3-6 21.3-.2 2.9 2.8 4.5 6.6 4.5 10.6s-1.5 7.8-4.3 10.7l-.2.2c-3 3-6.9 4.5-10.8 4.5z"
                />
                <path
                  d="M19 1C9.1 1 1 9.1 1 19s8.1 18 18 18 18-8.1 18-18S28.9 1 19 1zm-.1 33.1c-3.8 0-7.6-1.4-10.5-4.3-2.9-2.8-4.5-6.6-4.5-10.6s1.5-7.8 4.3-10.7l.2-.2c5.8-5.9 15.3-6 21.3-.2 2.9 2.8 4.5 6.6 4.5 10.6s-1.5 7.8-4.3 10.7l-.2.2c-3 3-6.9 4.5-10.8 4.5z"
                />
              </g>
            </svg>
            <svg
              *ngIf="!hasEnoughRBITS(i)"
              [routerLink]="['/info']"
              routerLinkActive="router-link-active"
              (mouseover)="infoHoverList[i].isHovered = true"
              class="info-icon lock cursor-pointer only-mobile-and-tablet"
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              viewBox="0 0 38 38"
              xml:space="preserve"
            >
              <g fill="#5e2466">
                <path
                  d="M19 9.2c-1.3 0-2.4 1.1-2.4 2.4S17.7 14 19 14s2.4-1.1 2.4-2.4-1.1-2.4-2.4-2.4zM19 16.6c-1.1 0-2 .9-2 2v8.3c0 1.1.9 2 2 2s2-.9 2-2v-8.3c0-1.2-.9-2-2-2z"
                />
                <path
                  d="M19 1C9.1 1 1 9.1 1 19s8.1 18 18 18 18-8.1 18-18S28.9 1 19 1zm-.1 33.1c-3.8 0-7.6-1.4-10.5-4.3-2.9-2.8-4.5-6.6-4.5-10.6s1.5-7.8 4.3-10.7l.2-.2c5.8-5.9 15.3-6 21.3-.2 2.9 2.8 4.5 6.6 4.5 10.6s-1.5 7.8-4.3 10.7l-.2.2c-3 3-6.9 4.5-10.8 4.5z"
                />
                <path
                  d="M19 1C9.1 1 1 9.1 1 19s8.1 18 18 18 18-8.1 18-18S28.9 1 19 1zm-.1 33.1c-3.8 0-7.6-1.4-10.5-4.3-2.9-2.8-4.5-6.6-4.5-10.6s1.5-7.8 4.3-10.7l.2-.2c5.8-5.9 15.3-6 21.3-.2 2.9 2.8 4.5 6.6 4.5 10.6s-1.5 7.8-4.3 10.7l-.2.2c-3 3-6.9 4.5-10.8 4.5z"
                />
              </g>
            </svg>
            <div
              *ngIf="!hasEnoughRBITS(i)"
              class="tooltip"
              [class.reveal]="infoHoverList[i].isHovered"
              (mouseover)="infoHoverList[i].isHovered = true"
              (mouseleave)="infoHoverList[i].isHovered = false"
            >
              <div class="row">{{ infoHoverList[i].tooltipText }}</div>
              <i></i>
            </div>
          </div>

          <div class="text-center">
            <p
              *ngIf="supplyDetails!==undefined"
              class="p-white-span-primary p16d14m mb10 mt10"
            >
              {{ supplyDetails[i]?.type }} (<span
                >{{ supplyDetails[i]?.currentSupply }}</span
              >)
            </p>
            <p
              *ngIf="supplyDetails!==undefined"
              class="p-white-span-primary p16d14m mb10"
            >
              {{ supplyDetails[i]?.price }} {{ supplyDetails[i]?.tokenName }}
            </p>

            <div
              class="border plus_minus flex mb20"
              *ngIf="supply[i]>0"
              [ngClass]="{'disabled-plus-minus': !hasEnoughRBITS(i)}"
            >
              <button
                class="minus flex1 pointer"
                (click)="onDecreaseSupplyInterestAmount(i)"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="14">
                  <path
                    fill="#fff"
                    d="M11 5.75v1.5q0 .312-.219.531T10.25 8H.75q-.312 0-.531-.219T0 7.25v-1.5q0-.312.219-.531T.75 5h9.5q.312 0 .531.219T11 5.75z"
                  />
                </svg>
              </button>
              <div class="flex1 border_left_right">
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="supply[i]"
                  readonly
                />
              </div>
              <button
                class="plus flex1 pointer"
                (click)="onIncreaseSupplyInterestAmount(i)"
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="11" height="14">
                  <path
                    fill="#fff"
                    d="M11 5.75v1.5q0 .312-.219.531T10.25 8H7v3.25q0 .312-.219.531T6.25 12h-1.5q-.312 0-.531-.219T4 11.25V8H.75q-.312 0-.531-.219T0 7.25v-1.5q0-.312.219-.531T.75 5H4V1.75q0-.312.219-.531T4.75 1h1.5q.312 0 .531.219T7 1.75V5h3.25q.312 0 .531.219T11 5.75z"
                  />
                </svg>
              </button>
            </div>

            <div class="soldout" *ngIf="supply[i] === 0">Sold Out</div>

            <a
              *ngIf="supply[i] > 0"
              href="javascript:void(0);"
              class="buy_button outlined-button mb10 text-inherit"
              [class.disabled]="!hasEnoughRBITS(i)"
              (click)="buyMoonBase(i)"
              (mouseover)="infoHoverList[i].isHovered = true"
              (mouseleave)="infoHoverList[i].isHovered = false"
              >Buy</a
            >
          </div>
        </div>
        <div  class="round-mini-button" (click)="next(+1)">
          <mat-icon fontSet="material-icons" class="color-primary"
            >arrow_forward</mat-icon
          >
        </div>
      </div>
    </div>

    <p
      *ngIf="!isConnected"
      class="mt20 fade-in-delayed pointer text-center"
      (click)="openDialog()"
    >
      Please connect your wallet
    </p>
  </div>
</section>
