<section class="navigation">
  <div class="bottom-container flex-center">
    <div class="inner">
      <div class="deeper flex1 no-padding">
        <ul class="sub-menu justify-around">
          <li *ngFor="let item of navSubItems">
            <div class="tooltip">
              <ng-container *ngIf="item.click == null; then itemWithRoute else itemWithClick"></ng-container>
              <ng-template #itemWithRoute>
                <img class="nav-bar-sub-item" src="{{ item.icon }}"
                  [ngClass]="{'active-icon': this.router.url == '/' ? false : item.route.includes(this.router.url)}"
                  [routerLink]="item.routerLink" routerLinkActive="router-link-active">
              </ng-template>
              <ng-template #itemWithClick>
                <img class="nav-bar-sub-item" (click)=item.click() src="{{ item.icon }}">
              </ng-template>
              <div class="bottom" [ngClass]="{'close-tooltip': !this.isTooltipActive}"
                (mouseleave)="this.isTooltipActive = true">
                <div class="row">
                  <p>{{ item.tooltip }}</p>
                </div>
                <i></i>
              </div>
            </div>
          </li>
          <li>
            <div class="tooltip">
              <img id="dropdwonButton" class="nav-bar-sub-item chain-button contain"
                [ngStyle]="{'background-color': chainConfigs[ChainId>0 ? ChainId : 1].bg}"
                src="{{ chainConfigs[ChainId>0 ? ChainId : 1].icon }}" (click)="toggleChainDropdown()">

              <div class="bottom" [ngClass]="{'close-tooltip': !this.isTooltipActive}"
                (mouseleave)="this.isTooltipActive = true">
                <div class="row">
                  <p>Select your Network</p>
                </div>
                <i></i>
              </div>
            </div>
            <div id="myDropdown" class="dropdown-content">
              <a *ngFor="let chain of chains;let i=index" (click)="handleSwitchNetwork(chainConfigs[chain].config,i)"
                [class.active]="ChainId == chain">
                {{ chainConfigs[chain]?.name ?? '' }}
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <nav class="flex-space-between" style="margin-bottom: 0.9rem;">
    <div class="logo-container flex border-0">
      <a href="javascript:void(0);"  class="flex-center" [routerLink]="['']">
        <img src="assets/media/images/carrot.webp" alt="">
        <img src="assets/media/images/white_logo.png" alt="" style="width: 156px;">
      </a>
    </div>


    <ul class="menubar flex">
      <li *ngFor="let item of navItems">
        <a href="javascript:void(0);"  [class.pri-btn]="item?.class == 'pri'" [routerLink]="[item.path]">{{ item.name }}</a>
      </li>
    </ul>
  </nav>


  <section class="mobile-only footer_menu tab-only navigation bottom-footer">
    <div class="res_menu flex1">

      <ul class="box">
        <ng-container *ngIf="menuItem ; then doNotShowMenuIcon else showMenuIcon">
        </ng-container>
        <ng-template #doNotShowMenuIcon>
          <li class="close-menu">
            <img src="assets/media/icons/close_bottom-menu_mobile.svg" (click)="closeMenu()">
          </li>
        </ng-template>
        <ng-template #showMenuIcon>
          <li class="hamburger-menu" (click)="menuopen()">
            <svg width="20px" height="26px" viewBox="0 0 32 25" version="1.1" xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink">
              <!-- Generator: Sketch 55.2 (78181) - https://sketchapp.com -->
              <title>Combined Shape</title>
              <desc>Created with Sketch.</desc>
              <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                <path
                  d="M0,0 L32,0 L32,3 L0,3 L0,0 Z M0,11 L32,11 L32,14 L0,14 L0,11 Z M0,22 L32,22 L32,25 L0,25 L0,22 Z"
                  id="Combined-Shape" fill="#afafaf"></path>
              </g>
            </svg>
          </li>
        </ng-template>
        <li>
          <div (click)="openDialog()" class="wallet_block flex-center">
            <svg version="1.1" id="Laag_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px" y="0px" viewBox="0 0 64 64" xml:space="preserve" style="enable-background: new 0 0 64 64;">
              <g _ngcontent-jde-c123="">
                <path
                  d="M18.7,12.8c-3.3,0-5.9,2.7-5.9,5.9v26.7c0,3.3,2.7,5.9,5.9,5.9h26.7c1.4,0,2.6-1.2,2.6-2.6v-6.8h3.3V31.4h-3.3v-6.8
                    c0-1.4-1.2-2.6-2.6-2.6h-4.1v-6.8c0-1.4-1.2-2.6-2.6-2.6H18.7z M45.4,48.8H18.7c-1.9,0-3.4-1.5-3.4-3.4V23.6l1.1,0.5
                    c0.7,0.3,1.5,0.5,2.4,0.5h26.7v6.8h-6.1c-2.9,0-5.2,2.4-5.2,5.3c0,2.9,2.4,5.2,5.2,5.2h6.1V48.8z M48.8,39.4h-9.4
                    c-1.5,0-2.8-1.2-2.8-2.8s1.2-2.8,2.8-2.8h9.4V39.4z M38.8,22.1H18.7c-1.9,0-3.4-1.5-3.4-3.4c0-1.9,1.5-3.4,3.4-3.4h20.1V22.1z">
                </path>
              </g>
            </svg>
          </div>
        </li>
      </ul>
    </div>
  </section>


  <section class="mobile-only moonbox-menu" *ngIf="menuItem">
    <div class="inner menu-item">

      <div class="no-padding balance-moonshot">
        <p class="moonshot-text"><span>RBITS BALANCE</span></p>
        <p><strong class="balance-text">{{ balance }}</strong></p>
      </div>

      <a class="p17 mb20" href="https://rbits.xyz">RBITS Home</a>
      <a class="p17 mb20" href="javascript:void(0);"  (click)="toggleTokenomics()">Tokenomics</a>
      <div *ngIf="isConnected" class="divider mb25"></div>
      <div *ngIf="isConnected" class="wallet-address mb25" [cdkCopyToClipboard]=address>
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Laag_1"
          x="0px" y="0px" viewBox="0 0 19.9 20" style="enable-background:new 0 0 19.9 20;" xml:space="preserve">
          <g>
            <path
              d="M3,0.1c-1.7,0-3,1.4-3,3V17c0,1.7,1.4,3,3,3h13.8c0.7,0,1.3-0.6,1.3-1.3v-3.5h1.7V9.7h-1.7V6.2c0-0.7-0.6-1.3-1.3-1.3h-2.1   V1.3c0-0.7-0.6-1.3-1.3-1.3H3V0.1z M16.8,18.7H3c-1,0-1.8-0.8-1.8-1.8V5.7l0.6,0.3c0.4,0.2,0.8,0.3,1.2,0.3h13.8v3.5h-3.2   c-1.5,0-2.7,1.2-2.7,2.7c0,1.5,1.2,2.7,2.7,2.7h3.2L16.8,18.7L16.8,18.7z M18.6,13.9h-4.9c-0.8,0-1.4-0.6-1.4-1.4   c0-0.8,0.6-1.4,1.4-1.4h4.9C18.6,11,18.6,13.9,18.6,13.9z M13.4,4.9H3c-1,0-1.8-0.8-1.8-1.8S2.1,1.4,3,1.4h10.4   C13.4,1.4,13.4,4.9,13.4,4.9z" />
          </g>
        </svg>
        <div class="address">
          <div class="truncate-middle" [attr.data-truncate]='address.slice(-3)'>
            <p>{{address.slice(0,-3)}}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</section>
