<div id="attribute-info">
  <p style="text-align:right" ><span style="color: #fff;right:0; cursor:pointer;" (click)="dialogRef.close()">X</span></p>
  <div *ngIf="isLoading" class="flex flex-center" style="max-width: 770px; width: 100vw;">
    <div class="loader-fixed" role="status">
      <img src="./assets/media/videos/Loading.gif" alt="" style="margin: auto;">

    </div>
</div>
  <div class="buttonsflex" >
    <span>Select All</span>
    <div  class="check-box-contianer" >
      <input  type="checkbox" (change)="handleOnChange($event)" [checked]="isSelectAll"><span >

      </span>
    </div>
    <button (click)="openBridgeNftStatusDialog(nftList.srcContractAddress,nftList.destContractAddress)" [disabled]="bridgeNftBtnTxt!='Bridge NFT'" class="view-detail-button outlined-button text-inherit" style="margin: 00px  0 0px auto;" >{{bridgeNftBtnTxt}}</button>
  </div>
  <div class="inner flex-wrap ">
    <ng-container *ngFor="let item of nftListData;index as i">

      <div class="box flex-center col pb100" >
        <div class="upgradenftid">
          {{item.token_id}}
        </div>
        <div  class="check-box-contianer" >
          <input  type="checkbox" (change)="handleSingleChange($event,i)" [checked]="item.isSelected"><span >
          </span>
        </div>
        <div class="relative nft_img">
          <img appLazyLoad="{{ item['image_path'] }}" class="img-fluid"  (error)="handleImageError($event)" alt="Image"  />
        </div>
        <div class="item-details text-center">
          <p class="item-name p-white-span-primary p16d14m  mt20" >{{item['nftName']|| item["name"]}}</p>
        </div>
      </div>
    </ng-container>
    <p *ngIf="nftList && nftList.nftData.length === 0 && !isLoading" class="absolute-center">No inventory data found</p>
  </div>
  <div class="text-center" *ngIf="cursor"><button (click)="fetchNFTsForContract()"  class="view-detail-button  outlined-button text-inherit" style="margin: 15px auto;">load more</button></div>
</div>
