<section class="scroller" style="margin-top:148px;" > <!-- style="margin-top:0px;min-height:64vh;height:0%;" -->
    <div class="pt-5 flex-space-between align-self">
        <div class="flex-col flex1">
            <div class="flex flex-col">
                <div class="flex justify-center">

                </div>
            </div>
            <div class="flex flex-col">
                <div class="flex flex-col w100 flex-center">
                    <h1 class="flex1 flex justify-center p30d24m orbitron bold flex-row flex1">
                        RBITS Games Hub
                    </h1>
                </div>
                <div class="upcoming flex flex-wrap wrap">
                    <div class="box flex-center col pa30">
                        <a href="javascript:void(0);"  class="flex1 game_img flex-center ">
                           <img src="assets/media/images/banner-hoppa.webp" alt="" style="width:100%">
                        </a>
                        <div class="flex flex-col flex1 w100 mt10">
                            <div class="word-break">
                                <div class="flex align-left flex-col mb10 mt10">
                                    <p class="p16">Jump into the action with Hoppa!<br>
                                        Our 2D platform game with charming<br>pixel art.
                                        Dodge obstacles, navigate<br>
                                        challenges, and conquer each level. </p>
                                </div>
                            </div>

                            <a class="button text-inherit mb20"
                                href="https://rbits.xyz/hoppa/" target="_blank">Play</a>
                        </div>
                    </div>
                    <div class="box flex-center col pa30">
                        <a class="flex1 game_img flex-center ">
                            <img src="assets/media/images/banner-raiders.webp" alt="" style="width:100%">
                         </a>
                        <div class="flex flex-col flex1 w100 mt10">
                            <div class="word-break">
                                <div class="flex align-left flex-col mb10 mt10">
                                    <p class="p16">
                                        Get ready for some serious space<br>
                                        dodging! Prevent the rabbits from<br>
                                        invading earth or die slowly from <br>
                                        nuclear raditation poisining<br>
                                    </p>
                                </div>
                            </div>
                           <a class="button text-inherit mb20"  target="_blank"
                                href="https://rbits.xyz/raiders/" target="_blank" >Play</a>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>
