<div>
    <div class="modal-box">
      <div class="flex justify-space-between walletconnect-modal__header">
        <div class="popup__title h4 p-white-span-primary">Follow steps</div>
        <div class="popup__title h4 p-white-span-primary cursor" (click)="closeDialog()">X</div>
                </div>
      <div class="popup popup_wallet" id="popup-wallet">
    <div class="steps">
      <!-- <div class="steps__item" *ngIf="!data.isArtistLootBox">
        <div class="steps__head">
          <div [ngClass]="{'success__icon': successIcon }" class=" steps__icon">
            <img *ngIf="!successIcon" src="https://cdn.dribbble.com/users/82092/screenshots/1073359/spinner.gif" class="loader-img">
            <svg *ngIf="successIcon" xmlns='http://www.w3.org/2000/svg' width='14' height='10' fill='white' viewBox='0 0 14 10'><path fill-rule='evenodd' d='M13.707.293a1 1 0 0 1 0 1.414l-8 8a1 1 0 0 1-1.414 0l-4-4a1 1 0 1 1 1.414-1.414L5 7.586 12.293.293a1 1 0 0 1 1.414 0z' fill='%23fcfcfd'/></svg>
          </div>
          <div class="steps__details">
            <div class="steps__info">Approve Moonshoot</div>

            <div class="steps__text">Send transaction with your wallet</div>
          </div>
        </div>
        <button class="button steps__button wp100 border-0">{{btn1Text}}</button>
      </div> -->
      <div class="steps__item">
        <div class="steps__head">
          <div [ngClass]="{'success__icon': successIcon2 }" class=" steps__icon">
            <img *ngIf="!successIcon2 && successIcon" src="./assets/media/videos/Loading.gif" class="loader-img">
           
            <svg *ngIf="!successIcon" _ngcontent-ygc-c122="" version="1.1" id="Laag_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 38 38" xml:space="preserve" style="enable-background: new 0 0 38 38;"><g _ngcontent-ygc-c122=""><g _ngcontent-ygc-c122=""><path _ngcontent-ygc-c122="" d="M29.7,17.3c-0.7,0-1.3,0.6-1.3,1.3c0,0.7,0.6,1.3,1.3,1.3c0.7,0,1.3-0.6,1.3-1.3l0,0C31,17.9,30.4,17.3,29.7,17.3z"></path><path _ngcontent-ygc-c122="" d="M29.7,20.3c-1,0-1.8-0.8-1.8-1.8c0-1,0.8-1.8,1.8-1.8c1,0,1.8,0.8,1.8,1.8C31.4,19.5,30.7,20.3,29.7,20.3z M29.7,17.8
                c-0.4,0-0.8,0.4-0.8,0.8c0,0.4,0.4,0.8,0.8,0.8c0.4,0,0.8-0.4,0.8-0.8C30.5,18.1,30.1,17.8,29.7,17.8z"></path></g><g _ngcontent-ygc-c122=""><path _ngcontent-ygc-c122="" d="M19,1.5C9.3,1.5,1.5,9.3,1.5,19c0,8.4,6,15.7,14.3,17.2c1.1,0.2,2.1,0.3,3.2,0.3c9.7,0,17.5-7.8,17.5-17.5
                C36.5,9.3,28.7,1.5,19,1.5z M19,34.8c-1,0-1.9-0.1-2.9-0.3C7.5,32.9,1.8,24.7,3.4,16.1C5,7.5,13.3,1.8,21.9,3.4
                c7.5,1.4,12.9,7.9,12.9,15.5C34.8,27.7,27.7,34.8,19,34.8z"></path><path _ngcontent-ygc-c122="" d="M19,37c-1.1,0-2.2-0.1-3.3-0.3C7.2,35.1,1,27.7,1,19c0-4.8,1.9-9.3,5.3-12.7C9.7,2.9,14.2,1,19,1l0,0c0,0,0,0,0,0
                c4.8,0,9.3,1.9,12.7,5.3C35.1,9.7,37,14.2,37,19C37,28.9,28.9,37,19,37C19,37,19,37,19,37z M19,2L19,2c-4.5,0-8.8,1.8-12,5
                c-3.2,3.2-5,7.5-5,12c0,8.2,5.8,15.2,13.9,16.7c1,0.2,2.1,0.3,3.1,0.3c0,0,0,0,0,0c9.4,0,17-7.7,17-17c0-4.5-1.8-8.8-5-12
                C27.8,3.7,23.5,2,19,2C19,2,19,2,19,2z M19,35.3c-1,0-2-0.1-3-0.3C7.2,33.4,1.3,24.8,3,16c0.8-4.3,3.2-8,6.8-10.5
                C13.4,3.1,17.7,2.2,22,3c7.7,1.4,13.3,8.2,13.3,16C35.3,28,28,35.3,19,35.3L19,35.3C19,35.3,19,35.3,19,35.3z M19,3.7
                c-3.1,0-6.1,0.9-8.6,2.7c-3.4,2.3-5.6,5.8-6.4,9.8c-1.5,8.3,4,16.3,12.3,17.9c0.9,0.2,1.9,0.3,2.8,0.3h0c8.4,0,15.3-6.9,15.3-15.3
                c0-7.4-5.3-13.7-12.5-15.1C20.9,3.8,19.9,3.7,19,3.7z"></path></g><g _ngcontent-ygc-c122=""><path _ngcontent-ygc-c122="" d="M13.5,27.2c-2.8-1.3-6.1-0.4-7.9,2.1c-0.2,0.3-0.2,0.8,0,1.1c2.6,3,6.2,5.1,10.1,5.8h0.2c0.3,0,0.6-0.2,0.8-0.5
                C18.2,32.5,16.8,28.7,13.5,27.2z M15.4,34.4c-3-0.7-5.8-2.3-7.9-4.6c0.9-0.9,2.1-1.4,3.4-1.4c2.6,0,4.7,2.1,4.7,4.7
                C15.6,33.5,15.5,34,15.4,34.4z"></path><path _ngcontent-ygc-c122="" d="M15.9,36.7C15.9,36.7,15.9,36.7,15.9,36.7h-0.2c-4.1-0.8-7.8-2.9-10.4-6c-0.4-0.5-0.4-1.2-0.1-1.7
                c1.9-2.7,5.5-3.6,8.5-2.3l0,0c3.5,1.6,5,5.7,3.4,9.1C16.9,36.4,16.5,36.7,15.9,36.7z M10.9,27.2c-1.9,0-3.7,0.9-4.8,2.5
                C6,29.8,6,30,6.1,30.1c2.5,2.9,6,4.9,9.8,5.6l0.1,0c0.2,0,0.3-0.1,0.3-0.2c1.3-3,0-6.5-2.9-7.8l0,0C12.5,27.3,11.7,27.2,10.9,27.2
                z M15.7,35l-0.5-0.1c-3.1-0.7-5.9-2.4-8.2-4.7l-0.3-0.3l0.3-0.3c1-1,2.3-1.6,3.7-1.6c0,0,0,0,0,0c2.9,0,5.2,2.3,5.2,5.2
                c0,0.5-0.1,1-0.2,1.4L15.7,35z M8.2,29.8c1.9,1.9,4.3,3.2,6.8,3.9c0-0.2,0.1-0.5,0.1-0.7c0-2.3-1.9-4.2-4.2-4.2c0,0,0,0,0,0
                C9.9,28.9,8.9,29.2,8.2,29.8z"></path></g><g _ngcontent-ygc-c122=""><path _ngcontent-ygc-c122="" d="M9.2,15.6c-2.1,0-3.8,1.7-3.8,3.8c0,2.1,1.7,3.8,3.8,3.8s3.8-1.7,3.8-3.8C13,17.3,11.3,15.6,9.2,15.6z M9.2,21.6
                c-1.2,0-2.1-1-2.1-2.1c0-1.2,1-2.1,2.1-2.1c1.2,0,2.1,1,2.1,2.1C11.3,20.6,10.3,21.6,9.2,21.6z"></path><path _ngcontent-ygc-c122="" d="M9.2,23.8c-2.4,0-4.3-1.9-4.3-4.3s1.9-4.3,4.3-4.3s4.3,1.9,4.3,4.3S11.6,23.8,9.2,23.8z M9.2,16.1c-1.9,0-3.4,1.5-3.4,3.4
                c0,1.9,1.5,3.4,3.4,3.4s3.4-1.5,3.4-3.4C12.5,17.6,11,16.1,9.2,16.1z M9.2,22c-1.4,0-2.6-1.2-2.6-2.6c0-1.4,1.2-2.6,2.6-2.6
                s2.6,1.2,2.6,2.6C11.8,20.9,10.6,22,9.2,22z M9.2,17.8c-0.9,0-1.7,0.7-1.7,1.7c0,0.9,0.7,1.6,1.7,1.6s1.7-0.7,1.7-1.6
                C10.8,18.5,10.1,17.8,9.2,17.8z"></path></g><g _ngcontent-ygc-c122=""><path _ngcontent-ygc-c122="" d="M27.3,7c-1.5,0-2.8,1.2-2.8,2.8s1.2,2.8,2.8,2.8c1.5,0,2.8-1.2,2.8-2.8S28.9,7,27.3,7z M27.3,10.9c-0.6,0-1.1-0.5-1.1-1.1
                c0-0.6,0.5-1.1,1.1-1.1c0.6,0,1.1,0.5,1.1,1.1C28.4,10.4,27.9,10.9,27.3,10.9z"></path><path _ngcontent-ygc-c122="" d="M27.3,13.1c-1.8,0-3.3-1.5-3.3-3.3s1.5-3.3,3.3-3.3c1.8,0,3.3,1.5,3.3,3.3S29.1,13.1,27.3,13.1z M27.3,7.5
                c-1.3,0-2.3,1-2.3,2.3s1,2.3,2.3,2.3c1.3,0,2.3-1,2.3-2.3S28.6,7.5,27.3,7.5z M27.3,11.4c-0.9,0-1.6-0.7-1.6-1.6s0.7-1.6,1.6-1.6
                c0.9,0,1.6,0.7,1.6,1.6S28.2,11.4,27.3,11.4z M27.3,9.2c-0.3,0-0.6,0.3-0.6,0.6s0.3,0.6,0.6,0.6c0.3,0,0.6-0.3,0.6-0.6
                S27.6,9.2,27.3,9.2z"></path></g><g _ngcontent-ygc-c122=""><path _ngcontent-ygc-c122="" d="M16.6,4.5c-2.2,0-4.1,1.8-4.1,4.1c0,2.2,1.8,4.1,4.1,4.1c2.2,0,4.1-1.8,4.1-4.1C20.7,6.3,18.9,4.5,16.6,4.5z M16.6,10.9
                c-1.3,0-2.4-1.1-2.4-2.4s1.1-2.4,2.4-2.4c1.3,0,2.4,1.1,2.4,2.4S17.9,10.9,16.6,10.9z"></path><path _ngcontent-ygc-c122="" d="M16.6,13.1c-2.5,0-4.5-2-4.5-4.5c0-2.5,2-4.5,4.5-4.5c2.5,0,4.5,2,4.5,4.5C21.2,11,19.1,13.1,16.6,13.1z M16.6,4.9
                c-2,0-3.6,1.6-3.6,3.6c0,2,1.6,3.6,3.6,3.6c2,0,3.6-1.6,3.6-3.6C20.2,6.6,18.6,4.9,16.6,4.9z M16.6,11.4c-1.6,0-2.8-1.3-2.8-2.8
                c0-1.6,1.3-2.8,2.8-2.8c1.6,0,2.8,1.3,2.8,2.8C19.5,10.1,18.2,11.4,16.6,11.4z M16.6,6.7c-1,0-1.9,0.8-1.9,1.9
                c0,1,0.8,1.9,1.9,1.9c1,0,1.9-0.8,1.9-1.9C18.5,7.5,17.7,6.7,16.6,6.7z"></path></g><g _ngcontent-ygc-c122=""><path _ngcontent-ygc-c122="" d="M25.8,21.6c-2.8,0-5.1,2.3-5.1,5.1s2.3,5.1,5.1,5.1c2.8,0,5.1-2.3,5.1-5.1S28.7,21.6,25.8,21.6z M25.8,30.1
                c-1.9,0-3.4-1.5-3.4-3.4c0-1.9,1.5-3.4,3.4-3.4c1.9,0,3.4,1.5,3.4,3.4C29.2,28.6,27.7,30.1,25.8,30.1z"></path><path _ngcontent-ygc-c122="" d="M25.8,32.3c-3.1,0-5.6-2.5-5.6-5.6c0-3.1,2.5-5.6,5.6-5.6s5.6,2.5,5.6,5.6C31.4,29.8,28.9,32.3,25.8,32.3z M25.8,22
                c-2.6,0-4.6,2.1-4.6,4.6s2.1,4.6,4.6,4.6s4.6-2.1,4.6-4.6S28.4,22,25.8,22z M25.8,30.6c-2.2,0-3.9-1.8-3.9-3.9s1.8-3.9,3.9-3.9
                s3.9,1.8,3.9,3.9S28,30.6,25.8,30.6z M25.8,23.8c-1.6,0-2.9,1.3-2.9,2.9c0,1.6,1.3,2.9,2.9,2.9s2.9-1.3,2.9-2.9
                C28.8,25.1,27.4,23.8,25.8,23.8z"></path></g></g></svg>

            <svg *ngIf="successIcon2  && successIcon" xmlns='http://www.w3.org/2000/svg' width='14' height='10' fill='white' viewBox='0 0 14 10'><path fill-rule='evenodd' d='M13.707.293a1 1 0 0 1 0 1.414l-8 8a1 1 0 0 1-1.414 0l-4-4a1 1 0 1 1 1.414-1.414L5 7.586 12.293.293a1 1 0 0 1 1.414 0z' fill='%23fcfcfd'/></svg>
          </div>
          <div class="steps__details">
            <div class="steps__info">Buy Rabbit Boxes</div>
            <div class="steps__text">Checking balance and approving</div>
          </div>
        </div>
        <!-- <button (click)="closeDialogDone()" class="button steps__button disabled wp100 border-0">{{btn2Text}}</button> -->
      </div>
    
    </div>
  </div>
  </div>