<div class="upcoming-container">
    <section class="scroller">

        <img *ngIf="!list" class="absolute-center" src="./assets/media/videos/Loading.gif" alt="loading" />
        <p *ngIf="!list" class="absolute-center" style="top:65%; color:white; margin-top: 20px; ">Loading ...</p>

        <div class="pt-5 flex1 h-100 flex-space-between align-self">
            <div class="flex-col flex1">
                <div class="flex flex-col">
                    <div class="flex">
                        <div class="flex flex-col w100 flex-center">
                            <h1 class="flex1 flex justify-center p30d24m orbitron bold flex-row flex1 mb30">Rabbit NFT Collections</h1>
                            <div class="tabs-container flex flex-xs-col mb10">
                                <a href="javascript:void(0);"  class="tab" [ngClass]="getButtonType(dropsCategory.RECENT)" (click)="changeTab(dropsCategory.RECENT)">Archive</a>
                                <a href="javascript:void(0);"  class="tab" [ngClass]="getButtonType(dropsCategory.LIVE)" (click)="changeTab(dropsCategory.LIVE)">Now</a>
                                <a href="javascript:void(0);"  class="tab" [ngClass]="getButtonType(dropsCategory.UPCOMING)" (click)="changeTab(dropsCategory.UPCOMING)">Next</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="list" class="flex flex-col">
                    <div class="upcoming flex flex-wrap wrap">
                        <ng-container *ngFor="let item of list[currentCategory]; let i = index; trackBy: trackByFn;">

<!--                        <div *ngIf="!(this.NSFWToggleState && item['NSFW'])" class="box flex-center col pa10"> -->


                            <div class="box flex-center col pa10">


                                <a *ngIf="item.revealDate !== 'Application form'" class="flex1 nft_img flex-center" [class.disabledBorder]="currentCategory === dropsCategory.UPCOMING" [routerLink]="currentCategory === dropsCategory.UPCOMING ? null : ['/artist',item.walletAddress]">
                                    <div class="relative res-box upcoming-img" [style.background]="'url('+item.filePath+')'"></div>
                                </a>

                                <!-- <a *ngIf="item.revealDate === 'Application form'" rel="noopener noreferrer"  href="{{item.url}}" target="_blank" class="flex1 nft_img flex-center">
                                    <div class="relative res-box upcoming-img" [style.background]="'url('+item.filePath+')'"></div>
                                </a> -->
                                <a href="javascript:void(0);"  *ngIf="item.revealDate === 'Application form'" rel="noopener noreferrer" (click)="openDialoagOfAddUser()" class="flex1 nft_img flex-center">
                                    <div class="relative res-box upcoming-img" [style.background]="'url('+item.filePath+')'"></div>
                                </a>

                                <div class="flex flex-col flex1 w100">
                                    <div class="text-center word-break">
                                        <div class="flex align-left flex-col mb20 mt20">
                                            <p class="p19" style="color: #983ca5;">{{item.name}}</p>
                                            <!-- <p class="color-primary">{{item.ArtistName}}</p> -->
                                        </div>
                                    </div>

                                    <div class="border-top-bottom  mb20" *ngIf="isShowPriceAvailability">
                                        <div class="flex justify-space-between mt10">
                                            <p class="color-primary mb10 mt10">Price</p>
                                            <p class="color-primary mb10 mt10"*ngIf="item?.is_free_mint">#Free ({{item?.free_nft_quantity - item?.free_mint_user}})</p>
                                            <p class="color-primary mb10 mt10" *ngIf="!item?.is_free_mint">{{getMinPrice(item)}} {{item.tokenName}}</p>

                                        </div>
                                        <div class="flex justify-space-between mb10">
                                            <p class="color-primary mb10">Availability</p>
                                            <p class="color-primary mb10">{{item.supply}}/{{item.TotalMaxSupply}}</p>
                                        </div>
                                    </div>

                                    <div *ngIf="currentCategory === dropsCategory.UPCOMING && item.revealDate !== 'Application form'" class="button text-inherit mb20 coundown-button default-cursor">
                                        <div class="tooltip">
                                            <div class="row">Available soon</div>
                                        </div>
                                        <img src="./assets/media/icons/drops/coundown.svg" alt="coundown-button">
                                        <app-countdown-timer [date]="item.revealDate" [nftData]="item" (timerUp)="isTimerUp($event)"></app-countdown-timer>
                                    </div>
                                    <a href="javascript:void(0);"  class="pointer button text-inherit mb20 coundown-button" (click)="openDialoagOfAddUser()" rel="noopener noreferrer"
                                    *ngIf="currentCategory === dropsCategory.UPCOMING && item.revealDate === 'Application form'">
                                    <img src="./assets/media/images/form.svg" alt="coundown-button">
                                    Application form
                                    </a>


                                    <a href="javascript:void(0);"  *ngIf="currentCategory === dropsCategory.LIVE && !item?.free_mint_user" class="button text-inherit mb20" [routerLink]="['/artist',item.walletAddress]">Buy Rabbit Boxes</a>
                                    <a href="javascript:void(0);"  *ngIf="currentCategory === dropsCategory.LIVE && item?.free_mint_user" class="button text-inherit mb20" [routerLink]="['/artist',item.walletAddress]">Get for Free</a>

                                    <a href="javascript:void(0);"  class="outlined-button mb20 text-inherit" (click)="viewDetails(item)">Collection info</a>
                                </div>
                            </div>
                        </ng-container>

                    </div>
                </div>
<!--
                <div *ngIf="currentCategory == dropsCategory.UPCOMING && list" class="flex flex-col">
                    <div class="upcoming flex flex-wrap wrap">
                        <div class="box flex-center col pa10">
                            <div class="flex flex-col flex1 w100">
                                <div class="text-center word-break">
                                    <div class="flex align-left flex-col mb10 ">
                                        <p class="p22 pa10 mb10" style="color: #983ca5;">The Animated Rabbit Series</p>
                                        <div class="relative upcoming-img">
                                            <video class="fade-in onvideo img-fluid" width="100%" height="auto" playsinline muted autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true">
                                                <source src="assets/media/videos/animatedrbits.mp4" type="video/mp4" />Browser not supported
                                              </video>
                                        </div>
                                    </div>
                                </div>

                                <div class="border-top-bottom  mb20">
                                    <div class="flex justify-space-between mt10">
                                        <p class="color-primary mb10 mt10">Price</p>
                                        <p class="color-primary mb10 mt10">#Free (50/80)</p>
                                        <p class="color-primary mb10 mt10">100 million RBITS</p>

                                    </div>
                                    <div class="flex justify-space-between mb10">
                                        <p class="color-primary mb10">Availability</p>
                                        <p class="color-primary mb10">88 pieces</p>
                                    </div>
                                </div>

                                <a href="javascript:void(0);"  class="outlined-button mb20 text-inherit" >Launch date to be announced</a>
                            </div>
                        </div>
                    </div>

                </div>
                -->
<!--
                <div *ngIf="list && list[currentCategory]?.length==0 && currentCategory == dropsCategory.UPCOMING" class="flex1 flex-center">
                  <p class="text-center options-color"  >Currently, there are no upcoming NFT collections scheduled for launch. <br> Check back soon for updates!</p>
                </div>
-->
                <div *ngIf="list && list[currentCategory]?.length==0 && currentCategory == dropsCategory.RECENT" class="flex1 flex-center">
                    <p class="text-center options-color" >Currently, there are no Archived NFT collections for the selected Blockchain</p>
                </div>
    <!--
                <div *ngIf="list && ( list[currentCategory]?.length==0 || list[currentCategory] === undefined ) && currentCategory == dropsCategory.LIVE" class="flex1 flex-center">
                    <p class="text-center options-color" >Currently, there are no NFT collections launched on the selected Blockchain</p>
                </div>
    -->
                <div *ngIf="currentCategory == dropsCategory.LIVE && list && list[currentCategory]?.length==0" class="flex1 flex-center">
                    <p class="text-center options-color" >Currently, there are no NFT collections launched on the selected Blockchain</p>
                </div>

            </div>

        </div>

    </section>
</div>


