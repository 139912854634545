<div class="ede2 addusermodal" *ngIf="formType == 'step1'">
 <div class="d-flex">
  <h4 class="headfont">Sign up</h4>
  <h4>

<!-- <a (click)="closeModal()"><svg class="croos" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 119.79"><title>close-window</title><path d="M23.5,0H99.38a23.56,23.56,0,0,1,23.5,23.5V96.29a23.56,23.56,0,0,1-23.5,23.5H23.5a23.44,23.44,0,0,1-16.6-6.9l-.37-.4A23.43,23.43,0,0,1,0,96.29V23.5A23.56,23.56,0,0,1,23.5,0ZM41,49.35a7,7,0,0,1,9.89-9.89L61.44,50,72,39.46a7,7,0,1,1,9.89,9.9L71.33,59.9,81.87,70.43A7,7,0,0,1,72,80.33L61.44,69.79,50.9,80.33A7,7,0,0,1,41,70.43L51.55,59.89,41,49.35ZM99.38,12.52H23.5a11,11,0,0,0-11,11V96.29a10.92,10.92,0,0,0,3,7.49l.27.26a11,11,0,0,0,7.75,3.23H99.38a11,11,0,0,0,11-11V23.5a11,11,0,0,0-11-11Z"/></svg></a> -->

<a href="javascript:void(0);"  (click)="closeModal()"><mat-icon> cancel_presentation</mat-icon></a>
</h4>
 </div>
  <div>
    <form [formGroup]="addArtistForm" (ngSubmit)="formRef.valid && onSubmit()" #formRef="ngForm">
      <div>

        <div class="mb-3 dgridsys">
          <label for="exampleInputEmail1" class=" labelname">Name</label>
          <input
            type="text"
            class=" contentcolor"
            id="text"
            aria-describedby="textHelp"
            formControlName="name"
          />
          <div *ngIf="f.name.invalid && (f.name.touched || formRef.submitted)">
              <span class="text-danger" *ngIf="f.name.errors?.required">Name required</span>
          </div>
        </div>
        <div class="mb-3 dgridsys ">
            <label for="exampleInputEmail1" class=" labelname">User Name</label>
            <input
              type="text"
              class=" contentcolor"
              id="text"
              aria-describedby="textHelp"
              formControlName="userName"
            />
            <div *ngIf="f.userName.invalid && (f.userName.touched || formRef.submitted)">
              <span class="text-danger" *ngIf="f.userName.errors?.required">User Name required</span>
          </div>
          </div>

          <div class="mb-3 dgridsys">
            <label for="exampleInputEmail1" class=" labelname">Wallet Address</label>
            <input
              type="text"
              class=" contentcolor"
              id="text"
              aria-describedby="textHelp"
              formControlName="walletAddress"
              [attr.disabled]="true"
            />
            <div *ngIf="f.walletAddress.invalid && (f.walletAddress.touched || formRef.submitted)">
              <span class="text-danger" *ngIf="f.walletAddress.errors?.required">Wallet Address required</span>
              <span class="text-danger" *ngIf="f.walletAddress.errors?.incorrect">Wallet Address is wrong</span>
          </div>
          </div>

          <div class="mb-3 dgridsys">
            <label for="exampleInputEmail1" class=" labelname">Collection Name</label>
            <input
              type="text"
              class=" contentcolor"
              id="text"
              aria-describedby="textHelp"
              formControlName="collectionName"
            />
            <div *ngIf="f.collectionName.invalid && (f.collectionName.touched || formRef.submitted)">
              <span class="text-danger" *ngIf="f.collectionName.errors?.required">Collection Name required</span>
          </div>
          </div>

          <div class="mb-3 dgridsys ">
            <label for="exampleInputEmail1" class=" labelname">Collection Symbol</label>
            <input
              type="text"
              class=" contentcolor"
              id="text"
              aria-describedby="textHelp"
              formControlName="symbol"
            />
            <div *ngIf="f.symbol.invalid && (f.symbol.touched || formRef.submitted)">
              <span class="text-danger" *ngIf="f.symbol.errors?.required">Symbol required</span>
          </div>
          </div>

      </div>
      <div class="buttonspace">
        <button type="submit" *ngIf="!transactionInitiated" class="btn subbutton">Submit</button>
        <button *ngIf="transactionInitiated" class="btn subbutton" type="button">Initiated...</button>
      </div>
    </form>
  </div>
</div>

<div class="ede2 addusermodal text-center" *ngIf="formType == 'step2'">
  <img src="./assets/gif/success.gif" style="width: 90%;
  height: auto;" >
  <div>
    <p class="mb-3" style="color: #5e2466;" >User has successfully registered.</p>
  </div>
  <div class="d-flex" >
    <button class="btn subbutton1" (click)="gotoAdminpanel()">Go to admin panel</button>
    <button class="btn outlined-button1"type="button" (click)="closeModal()" >cancel</button>
  </div>
</div>


<div class="ede2 addusermodal text-center" *ngIf="formType == 'step3'">

  <div>
    <p class="mb-3" style="color: #5e2466;" >User has already registered.</p>
  </div>
  <div class="d-flex" >
    <button class="btn subbutton1" (click)="gotoAdminpanel()">Go to admin panel</button>
    <button class="btn outlined-button1"type="button" (click)="closeModal()" >cancel</button>
  </div>
</div>
