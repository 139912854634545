<div id="attribute-info">
     <div style="text-align: end; cursor:pointer;" (click)="dialogRef.close()">  
        <svg width="27" height="27" xmlns="http://www.w3.org/2000/svg"><path d="M13.98 12.814 26.353.439l.707.707-12.375 12.375 12.375 12.374-.707.707-12.375-12.374L1.605 26.602l-.707-.707 12.374-12.374L.898 1.146 1.605.44l12.374 12.375Z" fill="#5e2466"/></svg>
    </div>
    <div class="flex1 flex-center flex-col flex-align-start">

        <div class="relative flex-col attribute-img">
            <img class="mb30" (error)="handleImageError($event)" *ngIf="fileTypeIsImage(item['logo_path'])" [src]="item['logo_path']" alt="img" />
            <video class="img-fluid mb30" alt="" *ngIf="!fileTypeIsImage(item['logo_path'])" loop autoplay playsinline oncanplay="this.play()" onloadedmetadata="this.muted = true">
                <source src="{{ item['logo_path'] }}" />
            </video>

            <div class="input-container flex justify-space-between align-center">
                <div class="tooltip">
                    <a href="{{link}}"  target="_blank" >
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="interactable mr10">
                            <path d="M21.41 11.58L12.42 2.59C12.02 2.19 11.52 2 11 2H4c-1.1 0-2 .9-2 2v7c0 .52.19 1.02.59 1.42l8.99 8.99c.78.78 2.04.78 2.83 0l7-7c.78-.79.78-2.05 0-2.83zM6 6.5C5.17 6.5 4.5 5.83 4.5 5S5.17 3.5 6 3.5 7.5 4.17 7.5 5 6.83 6.5 6 6.5z" fill="#5e2466"/>
                          </svg>
                    </a>
                      
                </div>

                <p  (click)="openTransferDialog(item)">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="interactable mr10" transform="rotate(315)">
                        <path d="M2 21L23 12 2 3 2 10 17 12 2 14z" fill="#5e2466"/>
                      </svg>
                </p>

                <a [href]="item.logo_path" target="_blank" download>
                    <svg xmlns="http://www.w3.org/2000/svg" class="interactable mr10" viewBox="0 0 49 49">
                        <path d="M24.5 0C11 0 0 11 0 24.5S11 49 24.5 49 49 38 49 24.5 38 0 24.5 0zM22 20.6V9.2h5v11.4h4.8l-7.3 9.2-7.3-9.2H22zm16.7 15.6H10.3v-10h4.3v6.3h19.7v-6.3h4.3v10z" fill="#5e2466"/>
                    </svg>
                </a>

                <p  (click)="openSocialShareDialog(item)">
                    <svg class="interactable" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49 49">
                        <g fill="#5e2466"><path d="M30.7 17.9c1.4 0 2.6-1.2 2.6-2.6s-1.2-2.6-2.6-2.6c-1.4 0-2.6 1.2-2.6 2.6s1.1 2.6 2.6 2.6zM14.2 22c-1.4 0-2.6 1.2-2.6 2.6 0 1.4 1.2 2.6 2.6 2.6s2.6-1.2 2.6-2.6c0-1.5-1.2-2.6-2.6-2.6z"/><path d="M24.5 0C11 0 0 11 0 24.5S11 49 24.5 49 49 38 49 24.5 38 0 24.5 0zm-4.8 25.9 7 3.9c1.1-1.1 2.5-1.6 4-1.6 3.1 0 5.7 2.5 5.7 5.7 0 3.1-2.5 5.7-5.7 5.7-.4 0-.9-.1-1.3-.2-3-.7-4.9-3.8-4.2-6.8l-7-3.9c-2.2 2.2-5.8 2.2-8 0s-2.2-5.8 0-8 5.8-2.2 8 0l7-3.9c-.5-1.9.1-4 1.5-5.4 2.2-2.2 5.9-2.2 8.1 0s2.2 5.9 0 8.1-5.9 2.2-8.1 0l-7 3.9c.2.7.2 1.6 0 2.5z"/><path d="M30.7 36.4c1.4 0 2.6-1.2 2.6-2.6 0-1.4-1.2-2.6-2.6-2.6-1.4 0-2.6 1.2-2.6 2.6 0 1.4 1.1 2.6 2.6 2.6z"/></g>
                    </svg>
                </p>
            </div>
            <button *ngIf="item['ethAddress']" (click)="openBridgeNftStatusDialog('eth')" class="outlined-button transfer-button mt10">Bridge to ETH</button>
            <button *ngIf="item['baseAddress']" (click)="openBridgeNftStatusDialog('base')" class="outlined-button transfer-button mt10">Bridge to BASE</button>
        </div>

        <div class="flex4 ml20">
            <div class="flex justify-space-between flex-align-start">
                <div class="flex flex-col">
                    <p class="p-white-span-primary p16d14m mb10 mt10">{{ item['name'] }}</p>
                    <p class="p-white-span-primary p16d14m mb10 mt10">{{ item['description'] }}</p>
                    <p class="p-white-span-primary p16d14m mb10 mt10">{{ item['rarity'] }}</p>
                    <p class="p-white-span-primary p16d14m mb30 mt10">NFT ID: {{ item['nftId'] }}</p>
                </div>

            </div>

            <table class="wp100 color-white" *ngIf="item['properties'] && item['properties'].length > 0">
                <thead>
                    <tr>
                        <th  >Attribute</th>
                        <th>Value</th>
                        <th>
                            <div class="rarity-column-text">Rarity
                                <div class="tooltip">
                                    <div class="row">These percentages express the given attribute rarity by evaluating the rarity of the items traits. A lower value is better.</div>
                                </div>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let propertiesData of item['properties']; trackBy: trackByFn;">
                        <td>{{ propertiesData['key'] }}</td>
                        <td>{{ propertiesData['value'] }}</td>
                        <td>{{ propertiesData['rarity'] }}%</td>
                    </tr>
                </tbody>
            </table>

        </div>
    </div>
</div>
