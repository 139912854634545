<section class="connect_popup padding-15 oweflow-1 height-auto">
  <div class="pb20 flex-col text-center">
    <p class="p-white-span-primary orbitron warning-title">
      ⚠️Wallet disconnect
    </p>
    <p class="p-white-span-primary orbitron mb20"> Are you sure you want to disconnect?</p>
  </div>
  <div class="flex-wrap justify-center">
    <button type="button" class="outlined-button button montserrat mb20 mr10" (click)="Disconnected()">Yes</button>
    <button type="button" class="outlined-button button bg-primary montserrat mb20" (click)="closeDialog()">No</button>
  </div>
</section>
