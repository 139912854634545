<section class="scroller">
    <div *ngIf="!historyData" class="flex-center main-message-container w-100 h-100 text-center pa20">
        {{mainMessage}}
        <div *ngIf="mainMessage === messages.IDLE && isConnected; else loggedOut">
            <img class="" src="./assets/media/videos/Loading.gif" alt="loading" />
            <p class="" style="top:65%; color:white; margin-top: 20px;">Loading ...</p>
        </div>

        <ng-template #loggedOut>
            <p class=" fade-in-delayed pointer" (click)="openDialog()">Please connect your wallet</p>
        </ng-template>
    </div>

    <div *ngIf="historyData !== null" class="mt50 flex-center flex-col mb-auto">

        <div class="tabs-container flex justify-space-around pb50">
            <a href="javascript:void(0)" class="tab tab-button" [ngClass]="getButtonType(tabsCategory.UNBOXINGS)" (click)="changeTab(tabsCategory.UNBOXINGS)">Unboxings</a>
            <a href="javascript:void(0)" class="tab tab-button" [ngClass]="getButtonType(tabsCategory.TRANSFERS)" (click)="changeTab(tabsCategory.TRANSFERS)">Transfers</a>
        </div>

        <div *ngIf="historyData && currentCategory === tabsCategory.UNBOXINGS" class="h-100 flex-space-between align-items-center">
            <div class="flex1 flex-column flex-center">
                <div class="table-responsive" style="min-height:35vmax;">
                    <table class="table mb20">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Tier</th>
                                <th>Qty</th>
                                <!-- <th>Verified seed</th> -->
                                <th>Claimed</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of buyHistoryData | paginate: { itemsPerPage: 9, currentPage: page }; let i=index">
                                <td *ngIf="innerWidth > 480">{{item.DateNFT}}</td>
                                <td *ngIf="innerWidth <= 480" class="text-center">
                                    {{item.DateNFT.split(' ')[0]}}<br>{{item.DateNFT.split(' ')[1]}}
                                </td>
                                <td style="min-width:85px;">{{item.BetTier}}</td>
                                <td class="text-center">{{item.nftData?.length}}</td>
                                <!-- <td>14849534</td> -->
                                <td class="">
                                    <button class="button claim-button" (click)="onClaimNFT(item,i)" *ngIf="!item.isClaimed">Claim</button>
                                    <p class="success p-10" *ngIf="item.isClaimed">Claimed</p>
                                </td>
                            </tr>
                            <tr *ngIf = "buyHistoryData?.length == 0" >
                                <td colspan="3" >Data Not Found</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div *ngIf="historyData && currentCategory === tabsCategory.UNBOXINGS && buyHistoryData && buyHistoryData?.length > 0" class="flex1 flex-center">
            <pagination-controls class="my-pagination" (pageChange)="page = $event" [maxSize]="maxSize">
            </pagination-controls>
        </div>

        <div *ngIf="historyData && currentCategory === tabsCategory.TRANSFERS" class="h-100 flex-space-between align-items-center">
            <div class="flex1 flex-column flex-center">
                <div class="table-responsive" style="min-height:35vmax;">
                    <table class="table mb20" style="width:25vmax; margin:0 auto;">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>NFT ID</th>
                                <th>Transaction</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of transferHistoryData | paginate: { itemsPerPage: 9, currentPage: page }; let i=index">
                                <td *ngIf="innerWidth > 480">{{item.DateNFT}}</td>
                                <td *ngIf="innerWidth <= 480" class="text-center">
                                    {{item.DateNFT.split(' ')[0]}}<br>{{item.DateNFT.split(' ')[1]}}
                                </td>
                                <td class="text-center">{{item.id}}</td>
                                <td>
                                    <a class="outlined-button  claim-button" href="{{'https://bscscan.com/tx/'+item.transactionHash}}" target="__blank">BSC Scan</a>
                                </td>
                            </tr>
                            <tr *ngIf = "transferHistoryData?.length == 0" >
                                <td colspan="3" >Data Not Found</td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>
        </div>
        <div *ngIf="historyData && currentCategory === tabsCategory.TRANSFERS && transferHistoryData && transferHistoryData?.length > 0" class="flex1 flex-center">
            <pagination-controls class="my-pagination" (pageChange)="page = $event" [maxSize]="maxSize">
            </pagination-controls>
        </div>

        <p *ngIf="historyData?.length === 0" class="absolute-center">No history data found</p>
    </div>

</section>
