<div class="transaction-Popup">
    <div class="video-box">
        <div class="mw-50" *ngIf="isCompletedProcess">
            <div class="shirts-carousel flex group-img justify-space-between align-center wp100">
                <div class="flex1 flex justify-end mr-3">
                    <div class="round-mini-button opacity0" *ngIf="nftImgRevealed.length > 1" [ngClass]="{'opacity1': nftrevealed}" (click)="prev()">
                        <mat-icon fontSet="material-icons" class="color-primary">arrow_back</mat-icon>
                    </div>
                </div>
                <div class="flex1 ph20">
                    <img class="flex mx-auto" *ngIf="!playvideo && !nftrevealed" src="assets/media/videos/Loading.gif" alt="">
                    <p [ngClass]="{'opacity1': nftrevealed}" (click)="closeDialog()" class="opacity0 flex justify-end pointer">
                        <svg fill="#5e2466" width="30" height="30" viewBox="0 0 27 27" xmlns="http://www.w3.org/2000/svg" class="mb10 interactable wrapped">
                        <path d="M13.98 12.814 26.353.439l.707.707-12.375 12.375 12.375 12.374-.707.707-12.375-12.374L1.605 26.602l-.707-.707 12.374-12.374L.898 1.146 1.605.44l12.374 12.375Z"/>
                      </svg>
                    </p>
                    <div class="relative fade-in">

                        <video *ngIf="playvideo" width="100%" height="auto" (ended)="nftVisible()" playsinline muted autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true">
                          <source src="{{videoSource[data.index]}}" type="video/webm" />
                        </video>

                        <video *ngIf="!checkFileType(nftImgRevealed[current].logo_path) && nftrevealed" class="fade-in onvideo img-fluid" width="100%" height="auto" (ended)="nftVisible()" playsinline muted autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true">
                          <source src="{{nftImgRevealed[current].logo_path}}" type="video/mp4" />Browser not supported
                        </video>

                        <img *ngIf="nftrevealed && checkFileType(nftImgRevealed[current].logo_path)" src="{{nftImgRevealed[current].logo_path}}" class="fade-in onvideo img-fluid" (error)="handleImageError($event)">
                    </div>
                    <div [ngClass]="{'opacity1': nftrevealed}" class="opacity0 flex justify-space-between">
                        <a href="javascript:void(0);"  class="fade-in flex1 mr10 outlined-button text-inherit mt10" (click)="closeDialog()" [routerLink]="['/inventory']">Go to Inventory</a>
                        <p class="amount-of-nft">{{current + 1}}/{{data.inputNumber}}</p>
                        <p class="share-button" (click)="openDialog()">
                            <svg version="1.1" id="share_twitter" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 49 49" style="enable-background:new 0 0 49 49" xml:space="preserve">
                            <g fill="#5e2466">
                              <path class="st0" d="M30.7 17.9c1.4 0 2.6-1.2 2.6-2.6s-1.2-2.6-2.6-2.6c-1.4 0-2.6 1.2-2.6 2.6s1.1 2.6 2.6 2.6zM14.2 22c-1.4 0-2.6 1.2-2.6 2.6 0 1.4 1.2 2.6 2.6 2.6s2.6-1.2 2.6-2.6c0-1.5-1.2-2.6-2.6-2.6z"/>
                              <path class="st0" d="M24.5 0C11 0 0 11 0 24.5S11 49 24.5 49 49 38 49 24.5 38 0 24.5 0zm-4.8 25.9 7 3.9c1.1-1.1 2.5-1.6 4-1.6 3.1 0 5.7 2.5 5.7 5.7 0 3.1-2.5 5.7-5.7 5.7-.4 0-.9-.1-1.3-.2-3-.7-4.9-3.8-4.2-6.8l-7-3.9c-2.2 2.2-5.8 2.2-8 0s-2.2-5.8 0-8 5.8-2.2 8 0l7-3.9c-.5-1.9.1-4 1.5-5.4 2.2-2.2 5.9-2.2 8.1 0s2.2 5.9 0 8.1-5.9 2.2-8.1 0l-7 3.9c.2.7.2 1.6 0 2.5z"/>
                              <path class="st0" d="M30.7 36.4c1.4 0 2.6-1.2 2.6-2.6 0-1.4-1.2-2.6-2.6-2.6-1.4 0-2.6 1.2-2.6 2.6 0 1.4 1.1 2.6 2.6 2.6z"/>
                            </g>
                          </svg>
                        </p>
                    </div>
                </div>
                <div class="flex1 ml-3 ml-0">
                    <div class="round-mini-button opacity0" *ngIf="nftImgRevealed.length > 1" [ngClass]="{'opacity1': nftrevealed}" (click)="next()">
                        <mat-icon fontSet="material-icons" class="color-primary">arrow_forward</mat-icon>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="modal-box" [ngClass]="{'in-active': isCompletedProcess}" *ngIf="!isCompletedProcess">
        <div class="flex justify-space-between walletconnect-modal__header">
            <div class="popup__title h4 p-white-span-primary">Follow steps</div>
        </div>
        <div class="popup popup_wallet" id="popup-wallet">
            <div class="steps">
                <div class="steps__item">
                    <div class="steps__head">
                        <div [ngClass]="{'success__icon': successIcon }" class=" steps__icon">
                            <img *ngIf="!successIcon" src="assets/media/videos/Loading.gif" class="loader-img">
                            <svg *ngIf="successIcon" xmlns="http://www.w3.org/2000/svg" width="14" height="10" fill="#fff"><path d="M13.707.293a1 1 0 0 1 0 1.414l-8 8a1 1 0 0 1-1.414 0l-4-4a1 1 0 1 1 1.414-1.414L5 7.586 12.293.293a1 1 0 0 1 1.414 0z"/></svg>
                        </div>
                        <div class="steps__details">
                            <div class="steps__info">Buy Rabbit Boxes</div>
                            <div class="steps__text">Checking balance and approving</div>
                        </div>
                    </div>
                    <button class="button disabled-button steps__button wp100 border-0">{{btn1Text}}</button>
                </div>
                <div class="steps__item">
                    <div class="steps__head">
                        <div [ngClass]="{'success__icon': successIcon2 }" class=" steps__icon">
                            <img *ngIf="!successIcon2 && successIcon" src="assets/media/videos/Loading.gif" class="loader-img">

                            <svg *ngIf="!successIcon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 38" xml:space="preserve">
                              <g fill="#ffffff">
                                <path d="M29.7 17.3c-.7 0-1.3.6-1.3 1.3 0 .7.6 1.3 1.3 1.3.7 0 1.3-.6 1.3-1.3 0-.7-.6-1.3-1.3-1.3z"/>
                                <path d="M29.7 20.3c-1 0-1.8-.8-1.8-1.8s.8-1.8 1.8-1.8 1.8.8 1.8 1.8c-.1 1-.8 1.8-1.8 1.8zm0-2.5c-.4 0-.8.4-.8.8s.4.8.8.8.8-.4.8-.8c0-.5-.4-.8-.8-.8z"/>
                                <path d="M19 1.5C9.3 1.5 1.5 9.3 1.5 19c0 8.4 6 15.7 14.3 17.2 1.1.2 2.1.3 3.2.3 9.7 0 17.5-7.8 17.5-17.5S28.7 1.5 19 1.5zm0 33.3c-1 0-1.9-.1-2.9-.3-8.6-1.6-14.3-9.8-12.7-18.4C5 7.5 13.3 1.8 21.9 3.4c7.5 1.4 12.9 7.9 12.9 15.5 0 8.8-7.1 15.9-15.8 15.9z"/>
                                <path d="M19 37c-1.1 0-2.2-.1-3.3-.3C7.2 35.1 1 27.7 1 19c0-4.8 1.9-9.3 5.3-12.7C9.7 2.9 14.2 1 19 1c4.8 0 9.3 1.9 12.7 5.3C35.1 9.7 37 14.2 37 19c0 9.9-8.1 18-18 18zm0-35c-4.5 0-8.8 1.8-12 5-3.2 3.2-5 7.5-5 12 0 8.2 5.8 15.2 13.9 16.7 1 .2 2.1.3 3.1.3 9.4 0 17-7.7 17-17 0-4.5-1.8-8.8-5-12-3.2-3.3-7.5-5-12-5zm0 33.3c-1 0-2-.1-3-.3C7.2 33.4 1.3 24.8 3 16c.8-4.3 3.2-8 6.8-10.5C13.4 3.1 17.7 2.2 22 3c7.7 1.4 13.3 8.2 13.3 16 0 9-7.3 16.3-16.3 16.3zm0-31.6c-3.1 0-6.1.9-8.6 2.7C7 8.7 4.8 12.2 4 16.2c-1.5 8.3 4 16.3 12.3 17.9.9.2 1.9.3 2.8.3 8.4 0 15.3-6.9 15.3-15.3 0-7.4-5.3-13.7-12.5-15.1-1-.2-2-.3-2.9-.3z"/>
                                <path d="M13.5 27.2c-2.8-1.3-6.1-.4-7.9 2.1-.2.3-.2.8 0 1.1 2.6 3 6.2 5.1 10.1 5.8h.2c.3 0 .6-.2.8-.5 1.5-3.2.1-7-3.2-8.5zm1.9 7.2c-3-.7-5.8-2.3-7.9-4.6.9-.9 2.1-1.4 3.4-1.4 2.6 0 4.7 2.1 4.7 4.7 0 .4-.1.9-.2 1.3z"/>
                                <path d="M15.9 36.7h-.2c-4.1-.8-7.8-2.9-10.4-6-.4-.5-.4-1.2-.1-1.7 1.9-2.7 5.5-3.6 8.5-2.3 3.5 1.6 5 5.7 3.4 9.1-.2.6-.6.9-1.2.9zm-5-9.5c-1.9 0-3.7.9-4.8 2.5-.1.1-.1.3 0 .4 2.5 2.9 6 4.9 9.8 5.6h.1c.2 0 .3-.1.3-.2 1.3-3 0-6.5-2.9-7.8-.9-.4-1.7-.5-2.5-.5zm4.8 7.8-.5-.1c-3.1-.7-5.9-2.4-8.2-4.7l-.3-.3.3-.3c1-1 2.3-1.6 3.7-1.6 2.9 0 5.2 2.3 5.2 5.2 0 .5-.1 1-.2 1.4v.4zm-7.5-5.2c1.9 1.9 4.3 3.2 6.8 3.9 0-.2.1-.5.1-.7 0-2.3-1.9-4.2-4.2-4.2-1 .1-2 .4-2.7 1zM9.2 15.6c-2.1 0-3.8 1.7-3.8 3.8 0 2.1 1.7 3.8 3.8 3.8s3.8-1.7 3.8-3.8c0-2.1-1.7-3.8-3.8-3.8zm0 6c-1.2 0-2.1-1-2.1-2.1 0-1.2 1-2.1 2.1-2.1 1.2 0 2.1 1 2.1 2.1s-1 2.1-2.1 2.1z"/>
                                <path d="M9.2 23.8c-2.4 0-4.3-1.9-4.3-4.3s1.9-4.3 4.3-4.3 4.3 1.9 4.3 4.3-1.9 4.3-4.3 4.3zm0-7.7c-1.9 0-3.4 1.5-3.4 3.4s1.5 3.4 3.4 3.4 3.4-1.5 3.4-3.4c-.1-1.9-1.6-3.4-3.4-3.4zm0 5.9c-1.4 0-2.6-1.2-2.6-2.6 0-1.4 1.2-2.6 2.6-2.6s2.6 1.2 2.6 2.6c0 1.5-1.2 2.6-2.6 2.6zm0-4.2c-.9 0-1.7.7-1.7 1.7 0 .9.7 1.6 1.7 1.6s1.7-.7 1.7-1.6c-.1-1-.8-1.7-1.7-1.7z"/>
                                <path d="M27.3 7c-1.5 0-2.8 1.2-2.8 2.8s1.2 2.8 2.8 2.8c1.5 0 2.8-1.2 2.8-2.8S28.9 7 27.3 7zm0 3.9c-.6 0-1.1-.5-1.1-1.1 0-.6.5-1.1 1.1-1.1.6 0 1.1.5 1.1 1.1 0 .6-.5 1.1-1.1 1.1z"/>
                                <path d="M27.3 13.1c-1.8 0-3.3-1.5-3.3-3.3s1.5-3.3 3.3-3.3c1.8 0 3.3 1.5 3.3 3.3s-1.5 3.3-3.3 3.3zm0-5.6c-1.3 0-2.3 1-2.3 2.3s1 2.3 2.3 2.3c1.3 0 2.3-1 2.3-2.3s-1-2.3-2.3-2.3zm0 3.9c-.9 0-1.6-.7-1.6-1.6s.7-1.6 1.6-1.6c.9 0 1.6.7 1.6 1.6s-.7 1.6-1.6 1.6zm0-2.2c-.3 0-.6.3-.6.6s.3.6.6.6.6-.3.6-.6-.3-.6-.6-.6z"/>
                                <path d="M16.6 4.5c-2.2 0-4.1 1.8-4.1 4.1 0 2.2 1.8 4.1 4.1 4.1 2.2 0 4.1-1.8 4.1-4.1s-1.8-4.1-4.1-4.1zm0 6.4c-1.3 0-2.4-1.1-2.4-2.4s1.1-2.4 2.4-2.4c1.3 0 2.4 1.1 2.4 2.4s-1.1 2.4-2.4 2.4z"/>
                                <path d="M16.6 13.1c-2.5 0-4.5-2-4.5-4.5s2-4.5 4.5-4.5 4.5 2 4.5 4.5c.1 2.4-2 4.5-4.5 4.5zm0-8.2c-2 0-3.6 1.6-3.6 3.6s1.6 3.6 3.6 3.6 3.6-1.6 3.6-3.6c0-1.9-1.6-3.6-3.6-3.6zm0 6.5c-1.6 0-2.8-1.3-2.8-2.8 0-1.6 1.3-2.8 2.8-2.8 1.6 0 2.8 1.3 2.8 2.8.1 1.5-1.2 2.8-2.8 2.8zm0-4.7c-1 0-1.9.8-1.9 1.9 0 1 .8 1.9 1.9 1.9 1 0 1.9-.8 1.9-1.9 0-1.1-.8-1.9-1.9-1.9z"/>
                                <path d="M25.8 21.6c-2.8 0-5.1 2.3-5.1 5.1s2.3 5.1 5.1 5.1 5.1-2.3 5.1-5.1-2.2-5.1-5.1-5.1zm0 8.5c-1.9 0-3.4-1.5-3.4-3.4s1.5-3.4 3.4-3.4 3.4 1.5 3.4 3.4-1.5 3.4-3.4 3.4z"/>
                                <path d="M25.8 32.3c-3.1 0-5.6-2.5-5.6-5.6 0-3.1 2.5-5.6 5.6-5.6s5.6 2.5 5.6 5.6c0 3.1-2.5 5.6-5.6 5.6zm0-10.3c-2.6 0-4.6 2.1-4.6 4.6s2.1 4.6 4.6 4.6 4.6-2.1 4.6-4.6-2-4.6-4.6-4.6zm0 8.6c-2.2 0-3.9-1.8-3.9-3.9s1.8-3.9 3.9-3.9 3.9 1.8 3.9 3.9-1.7 3.9-3.9 3.9zm0-6.8c-1.6 0-2.9 1.3-2.9 2.9 0 1.6 1.3 2.9 2.9 2.9s2.9-1.3 2.9-2.9c.1-1.6-1.3-2.9-2.9-2.9z"/>
                              </g>
                            </svg>
                            <svg *ngIf="successIcon2  && successIcon" xmlns="http://www.w3.org/2000/svg" width="14" height="10" fill="#fff"><path fill-rule="evenodd" d="M13.707.293a1 1 0 0 1 0 1.414l-8 8a1 1 0 0 1-1.414 0l-4-4a1 1 0 1 1 1.414-1.414L5 7.586 12.293.293a1 1 0 0 1 1.414 0z"/></svg>
                        </div>
                        <div class="steps__details">
                            <div class="steps__info">Claim NFT</div>
                            <div class="steps__text">Please wait, retrieving data</div>
                        </div>
                    </div>
                    <button (click)="closeDialogDone()" class="button disabled-button steps__button disabled wp100 border-0">{{btn2Text}}</button>
                </div>

            </div>
        </div>
    </div>
