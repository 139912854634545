<section id="info-moonboxes" class="info-moonboxes">
  <div class="all-boxes">
    <div class="box-container" *ngFor="let box of boxes">
      <div class="box-card">
        <img class="rounded-image" src={{box.img}} alt="RBITS">
        <div class="desc">
          <p>{{box.name}}</p>
          <!-- <p class="quantity">{{box.quantity}}</p> -->
        </div>
      </div>
    </div>
  </div>
  <div class="all-boxes-mobile">
    <div class="round-mini-button" (click)="prevBox()">
      <mat-icon fontSet="material-icons" class="color-primary">arrow_back</mat-icon>
    </div>
    <div class="box-container">
      <div class="box-card">
        <img class="rounded-image" src={{boxForMobile.img}} alt="RBITS">
        <div class="desc">
          <p>{{boxForMobile.name}}</p>
          <!-- <p class="quantity">{{boxForMobile.quantity}}</p> -->
        </div>
      </div>
    </div>
    <div class="round-mini-button" (click)="nextBox()">
      <mat-icon fontSet="material-icons" class="color-primary">arrow_forward</mat-icon>
    </div>
  </div>

  <div class="detail-container">
    <div class="description-container">
      <div class="bottom flex pv20 mb30">
        <div class="bottom-only no-padding ">
          <p class="mb30  info-text">
            Rabbit Boxes operates as an NFT launchpad and Gaming Hub featuring four distinct types of boxes. Accessing these boxes requires holding a certain amount of RBITS tokens, as outlined in the following table:
          </p>

          <div class="tier-table">
            <div class="table-columns-titles">
              <p>Tier</p>
              <p class="pr">RBITS </p>
            </div>
            <div class="table-row">
              <div class="tier">
                <p>Wood</p>
                <p>Silver</p>
                <p>Gold</p>
                <p>Diamond</p>
              </div>
              <div class="requirement">
                <p>No requirement</p>
                <p> 50 Billion</p>
                <p> 75 Billlion</p>
                <p>1 Trillion</p>
              </div>
            </div>
          </div>
          <p class="info-text">
            <br>Additionally, holders of the Diamond Tier enjoy a one-hour advance access window prior to the public release of the collection.<br>
            <br> 
            It's important to note that holding a higher tier also grants eligibility to open Rabbit Boxes of lower tiers.<br><br>
           
          </p>
        </div>
      </div>
      <div class="buttons-container">
        <div class="read-more-button">
          <a [routerLink]="['/live']" class="outlined-button">Explore</a>
        </div>

        <div class="line-for-buttons only-mobile"></div>

      </div>
    </div>
  </div>
</section>
