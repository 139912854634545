<section class="landing-intro">
 <!-- <div class="landing-into-nft-image-container-mobile">
    <section class="landing-carousel">
      <swiper *ngIf="slides.length !== 0" [config]="config">
        <ng-container *ngFor="let slide of slides; index as i">
          <ng-template swiperSlide>
            <div class="nft-image-container">
              <img src="{{slide.img}}" alt="{{slide.name}}">
            </div>
          </ng-template>
        </ng-container>
      </swiper>
      <img class="flex mx-auto" style="margin-bottom: 80px;" *ngIf="slides.length === 0"
        src="assets/media/videos/Loading.gif" alt="">
    </section>
  </div> -->

  <div class="info">
    <div class="line-container ">
      <div class="line flex1"></div>
      <div class="empty flex1"></div>
    </div>
    <div class="top futuristic-container bottom-only">
      <p class="p-white-span-primary p34 orbitron info-title">Rabbit Boxes<br><span>expand your <br
            class="break-line">NFT collection <br class="break-line">and play games</span></p>
    </div>
    <div class="bottom flex pv20">
      <div class="flex2  bottom-only no-padding mr10">
        <p class="mb20  info-text">Acquiring RBITS offers a distinctive opportunity to expand your Rabbit NFT collection. Rabbit Boxes is supported on Ethereum, Binance Smart Chain, MoonRiver, Polygon, and DogeChain.</p>
      </div>

    </div>
    <div class="buttons-container">
      <div class="just-another-button">
        <a href="javascript:void(0);"   [routerLink]="['/live']" class="button">Explore</a>
      </div>
      <div class="just-another-button ml20">
        <a [routerLink]="['/games']" class="button">Play</a>
      </div>
    </div>

  </div>

  <div class="landing-into-nft-image-container">
    <!--<section class="landing-carousel" style="width:470px; height:470px;">
      <swiper *ngIf="slides.length !== 0" [config]="config">
        <ng-container *ngFor="let slide of slides; index as i">
          <ng-template swiperSlide>
            <div class="nft-image-container">
              <img src="{{slide.img}}" alt="{{slide.name}}">
            </div>
          </ng-template>
        </ng-container>
      </swiper>
      <div *ngIf="slides.length === 0" class="flex flex-center" style="max-width: 770px; width: 100vw;">
        <div class="moonbox"></div>
      </div>
    </section>
  -->
    <div class="nft-iamge-container">
      <div class="moonbox" [style.background-image]="'url(\'' + assetBaseUrl + '/media/sprites/moonboxes/wood.webp\')'"></div>
    </div>
  </div>
</section>
