<section class="connect_popup padding-15 oweflow-1">
  <div class="wrap-flex pb20">
    <h1 class="p-white-span-primary orbitron warning-title">
      NFT Migration
    </h1>
    <p class="p-white-span-primary orbitron mb20">
      We've noticed that there are still NFTs linked to an outdated contract. To maintain the integrity of your assets, we kindly ask you to begin the migration process to transfer your NFTs to the new contract.
    </p>
    <div class="flex-wrap grid-gap">
      <div class="box" *ngFor="let tab of tabs?.data; let index = index">
        <div> <img src="{{ tab.logo_path }}" height="100" alt=""></div>
        <div class="label-color">
          {{ tab.nft_name }}
        </div>
      </div>
    </div>
  </div>
  <p class="p-white-span-primary  mb20 align-items-center" style="text-align: center;"> Upgraded {{ swapCount }} /
    {{ nftCountToSwap }}</p>
  <div class="btn-right mb10">
    <button type="button" class="button mx-auto montserrat" (click)="migrate()">Upgrade</button>
  </div>
  <div class="btn-left mb10">
    <button type="button" class="button mx-auto montserrat" (click)="closeDialog()">Cancel</button>
  </div>
</section>
