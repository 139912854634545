<div class="timermodal"  >
    <div class="svgposition">
        <svg  width="27" height="27" (click)="closeModal()" class=""  xmlns="http://www.w3.org/2000/svg" class="interactable wrapped" ng-reflect-dialog-result="true" type="button"><path _ngcontent-isq-c163="" _ngcontent-gte-c68="" d="M13.98 12.814 26.353.439l.707.707-12.375 12.375 12.375 12.374-.707.707-12.375-12.374L1.605 26.602l-.707-.707 12.374-12.374L.898 1.146 1.605.44l12.374 12.375Z" fill="#5e2466"  ></path></svg>

    </div>

    <p class="timertext" >{{data.name | titlecase}} has just released! Let me take you there.</p>
    <div class="yesNoButton" >
        <button class="timerbutton " type="button" (click)="gotoLive()" >Yes</button>
        <button class="timerbutton " type="button" (click)="closeModal()" >No</button>
    </div>
</div>