<section id="landing-nfts" class="landing-nfts" style="margin: auto;">
    <section class="landing-carousel">
        <swiper *ngIf="slides.length !== 0" [config]="config" (activeIndexChange)="onIndexChange($event)" (slideChange)="onIndexChange($event)" (realIndexChange)="onIndexChange($event)">
            <ng-container *ngFor="let slide of slides; index as i">
                <ng-template swiperSlide>
                    <div class="nft-image-container" [routerLink]="['/'+slide.url]" routerLinkActive="router-link-active">
                        <img src="{{slide.img}}"  (error)="handleImageError($event)" alt="{{slide.name}}">
                    </div>
                </ng-template>
            </ng-container>
        </swiper>
        <div *ngIf="slides.length === 0 && landingSliderProvider.isLoading" class="flex flex-center" style="max-width: 770px; width: 100vw;">
            <img class="flex mx-auto" style="margin-bottom: 40px;"  src="assets/media/videos/Loading.gif" alt="">
        </div>
    </section>
    <p class="slider-name">{{currentSliderName}}</p>

    <div class="detail-container">
        <div class="heading-container top futuristic-container bottom-only">
            <p class="p-white-span-primary p34 orbitron">rbits.xyz/boxes<br><span>Your hub<br class="break-line-desktop"> to Rabbits NFTs & Rabbit Games</span></p>
        </div>
        <div class="description-container">
            <div class="bottom flex pv20">
                <div class="bottom-only no-padding ">
                    <p class="mb20  info-text"><a href="https://rbits.xyz/boxes/">rbits.xyz</a> offers you a compresenhsive overview of Rabbit NFT drops.<br><br> <a href="https://rbits.xyz/boxes/" target="_blank">rbits.xyz</a> is the hub for RBITS games and RBITS collectibles.
                    </p>
                </div>
            </div>
            <div class="buttons-container">
                <div class="just-another-button">
                    <a href="javascript:void(0);"  [routerLink]="['/live']" class="button">Explore</a>
                </div>
            </div>
        </div>
    </div>

</section>

<div class="desktop-only" style="width: 70%; margin:0rem auto 8rem ;">
    <img src="assets/media/images/moonbox/Stick.webp" alt="" width="100%"  >
</div>

<div class="mobile-only">
    <img src="assets/media/images/moonbox/Stick.webp" alt="" width="100%"  >
</div>
