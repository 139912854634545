<div class="stats flex1">


</div>
<div id="footer-count-container" class="info flex4">
  <div class="desktop-bottom-count shape text-center bottom flex ph50">
  <!-- <div [ngClass]="{'bg-black':bgChange}" class="desktop-bottom-count shape text-center bottom flex ph50"> -->

    <div class="flex1 no-padding">
      <p class="p-white-span-primary">
        <span class="p12" style="color: white;">TIER</span>
      </p>
      <p class="p-white-span-primary">
        <span style="color: white; margin-top: 10px;">
          <strong>
            {{eligibleTier}}
          </strong>
        </span>
      </p>
    </div>
    <div class="flex1 no-padding down_box">
      <p class="p12 mt-15">
        <span style="color: white;">RBITS BALANCE</span>
      </p>

      <p style="color: white;  margin-top: 10px;">
        <strong>
          {{balance}}
        </strong>
      </p>
    </div>
    <div class="flex1 no-padding">
      <p class="p12 p-white-span-primary" ><span style="color: white;">#NFTS</span></p>
      <p class="p-white-span-primary" style="color: white;">
        <span style="color: white;  margin-top: 10px;">
          <strong>
            {{moonCountData === undefined ? "-" : moonCountData.nftTotal}}
          </strong>
        </span>
      </p>
    </div>
  </div>
</div>
<div class="stats flex1"></div>




<section class="mobile-only footer_menu tab-only navigation">
  <div class="desktop-bottom-count flex1 no-padding shape_down_box">
    <p class="p12 mt-15"><span>RBITS Balance</span></p>
    <p><strong class="p14">Awaiting Connection</strong></p>
  </div>

  <div class="res_menu flex1">
    <ul class="box">
      <li>
        <svg width="32px" height="25px" viewBox="0 0 32 25" version="1.1" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink">
          <!-- Generator: Sketch 55.2 (78181) - https://sketchapp.com -->
          <title>Combined Shape</title>
          <desc>Created with Sketch.</desc>
          <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path d="M0,0 L32,0 L32,3 L0,3 L0,0 Z M0,11 L32,11 L32,14 L0,14 L0,11 Z M0,22 L32,22 L32,25 L0,25 L0,22 Z"
              id="Combined-Shape" fill="#5e2466"></path>
          </g>
        </svg>
      </li>
      <li>
        <svg width="27px" height="27px" viewBox="0 0 27 27" version="1.1" xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink">
          <title _ngcontent-jde-c121="">Combined Shape</title>
          <desc _ngcontent-jde-c121="">Created with Sketch.</desc>
          <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <path
              d="M13.9791847,12.8137085 L26.3535534,0.439339828 L27.0606602,1.14644661 L14.6862915,13.5208153 L27.0606602,25.895184 L26.3535534,26.6022907 L13.9791847,14.2279221 L1.60481605,26.6022907 L0.897709268,25.895184 L13.2720779,13.5208153 L0.897709268,1.14644661 L1.60481605,0.439339828 L13.9791847,12.8137085 Z"
              id="Combined-Shape" fill="#000000"></path>
          </g>
        </svg>
      </li>
      <li>
        <div class="wallet_block flex-center">
          <svg version="1.1" id="Laag_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px" y="0px" viewBox="0 0 64 64" xml:space="preserve" style="enable-background: new 0 0 64 64;">
            <g _ngcontent-jde-c123="">
              <path
                d="M18.7,12.8c-3.3,0-5.9,2.7-5.9,5.9v26.7c0,3.3,2.7,5.9,5.9,5.9h26.7c1.4,0,2.6-1.2,2.6-2.6v-6.8h3.3V31.4h-3.3v-6.8
                    c0-1.4-1.2-2.6-2.6-2.6h-4.1v-6.8c0-1.4-1.2-2.6-2.6-2.6H18.7z M45.4,48.8H18.7c-1.9,0-3.4-1.5-3.4-3.4V23.6l1.1,0.5
                    c0.7,0.3,1.5,0.5,2.4,0.5h26.7v6.8h-6.1c-2.9,0-5.2,2.4-5.2,5.3c0,2.9,2.4,5.2,5.2,5.2h6.1V48.8z M48.8,39.4h-9.4
                    c-1.5,0-2.8-1.2-2.8-2.8s1.2-2.8,2.8-2.8h9.4V39.4z M38.8,22.1H18.7c-1.9,0-3.4-1.5-3.4-3.4c0-1.9,1.5-3.4,3.4-3.4h20.1V22.1z">
              </path>
            </g>
          </svg>
        </div>
      </li>
    </ul>
  </div>
</section>
