<div id="collection-info" class="collection-info h-100">
    <div class="" [mat-dialog-close]="" style="text-align: end;">
        <svg class="interactable wrapped" width="27" height="27" xmlns="http://www.w3.org/2000/svg"><path d="M13.98 12.814 26.353.439l.707.707-12.375 12.375 12.375 12.374-.707.707-12.375-12.374L1.605 26.602l-.707-.707 12.374-12.374L.898 1.146 1.605.44l12.374 12.375Z" fill="#5e2466"/></svg>
    </div>
    <div class="collection-inner h-100">

        <div class="left flex1">
            <section class="carousel">
                <swiper [config]="swiperConfig">
                    <ng-container *ngFor="let slide of slides; index as i">
                        <ng-template swiperSlide>
                            <div class="nft-image-container">
                                <video *ngIf="!checkFileType(slide.logo_path)"  class="fade-in onvideo img-fluid" width="100%" height="auto"  playsinline muted autoplay  onloadedmetadata="this.muted = true">
                                  <source src="{{slide.logo_path}}" type="video/mp4" />Browser not supported
                                </video>

                                <img *ngIf="checkFileType(slide.logo_path)" src="{{slide.logo_path}}" class="fade-in onvideo img-fluid" (error)="handleImageError($event)">
                            </div>
                        </ng-template>
                    </ng-container>
                </swiper>
            </section>
        </div>

        <div class="right flex1 ml20 flex flex-col justify-space-between">

            <div class="flex justify-space-between flex-align-start border-bottom">
                <div class="flex flex-col clipboard">
                    <p class="p18 w700 mt10 color-white">{{artistData.collectionName}}</p>
                   <!--<p class="mb10 mt10 color-primary" >{{artistData.artistName}}</p>-->
                   <p class="mb10 mt10 color-primary p12">By: {{artistData.artistAddress}}</p>

                </div>
            </div>

            <section class="carousel-mobile mt20">
                <swiper [config]="swiperConfigMobile">
                    <ng-container *ngFor="let slide of slides; index as i">
                        <ng-template swiperSlide>
                            <div class="nft-image-container">
                                <img src="{{getPreviewImageUrl(slide.logo_path)}}" alt="{{slide.nftId}}">
                            </div>
                        </ng-template>
                    </ng-container>
                </swiper>
            </section>

            <textarea readonly class="mt20 justify-space-between flex description p16">{{artistData?.description}}</textarea>

            <ul class="social-icons-container flex-center flex-wrap justify-space-between ">
                <li>
                    <a href="javascript:void(0);" *ngIf="artistData.discord == ''" class="disabled-icon">
                        <img  src="./assets/media/icons/moonbase/discord.svg" alt="">
                    </a>
                    <a *ngIf="artistData.discord != ''" href="{{artistData.discord}}" rel="noopener noreferrer" target="_blank">
                        <img src="./assets/media/icons/moonbase/discord.svg">
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0);"  *ngIf="artistData.twitter == ''" class="disabled-icon">
                        <img src="./assets/media/icons/moonbase/twitter.svg" alt="">
                    </a>
                    <a *ngIf="artistData.twitter != ''" href="{{artistData.twitter}}" rel="noopener noreferrer" target="_blank">
                        <img src="./assets/media/icons/moonbase/twitter.svg">
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0);"  *ngIf="artistData.telegram == ''" class="disabled-icon">
                        <img src="./assets/media/icons/moonbase/telegram.svg" alt="">
                    </a>
                    <a *ngIf="artistData.telegram != ''" href="{{artistData.telegram}}" rel="noopener noreferrer" target="_blank">
                        <img src="./assets/media/icons/moonbase/telegram.svg">
                    </a>
                </li>
                <li>
                    <a href="javascript:void(0);"  *ngIf="artistData.instagram == ''" class="disabled-icon">
                        <img src="./assets/media/icons/moonbase/instagram.svg" alt="">
                    </a>
                    <a *ngIf="artistData.instagram != ''" href="{{artistData.instagram}}" rel="noopener noreferrer" target="_blank">
                        <img src="./assets/media/icons/moonbase/instagram.svg">
                    </a>
                </li>
                <li>
                    <a  href="javascript:void(0);"  *ngIf="artistData.opensea == ''" class="disabled-icon">
                        <img src="./assets/media/icons/moonbase/opensea.svg" alt="">
                    </a>
                    <a *ngIf="artistData.opensea != ''" href="{{artistData.opensea}}" rel="noopener noreferrer" target="_blank">
                        <img src="./assets/media/icons/moonbase/opensea.svg">
                    </a>
                </li>
                <li>
                    <a  href="javascript:void(0);"  *ngIf="artistData.website == '' " class="disabled-icon">
                        <img src="./assets/media/icons/moonbase/global.svg" alt="">
                    </a>
                    <a *ngIf="artistData.website != '' " href="{{artistData.website}}" rel="noopener noreferrer" target="_blank">
                        <img src="./assets/media/icons/moonbase/global.svg">
                    </a>
                </li>
            </ul>

        </div>
    </div>

</div>
