<section class="tokenomics">
  <img class="close" (click)="toggleTokenomics()" src="./assets/media/icons/close.svg" alt="close">

  <div class="inner balloon">
    <h1 class="p30 w900 white my-3">Tokenomics</h1>
    <div class="block">
      <p class="p17 mb10 spacing">
        Staking: 2% is distributed to holders.
      </p>
      <p class="p17 mb10 spacing">
        Liquidity: 2% is auto-added to LP.
      </p>
      <p class="p17 mb10 spacing">
        Treasury: 1% is added to project fund.
      </p>
    </div>
    <div class="deeper flex-space-between">
      <div class="left">
        <ul>
          <li *ngFor="let item of list[0]">
            <div>
              <p class="white p12 uppercase mb-3">{{item.key}}</p>
              <p class="w700 white p18 text-right">{{item.val}}
              </p>
              <p class="w300 p12 text-right invisible-md">{{item.shortVal}}</p>
            </div>
          </li>
        </ul>
      </div>
      <div class="right">
        <ul>
          <li *ngFor="let item of list[1]">
            <div>
              <p class="white p12 uppercase">{{item.key}}</p>
              <p class="w700 white p18 text-right">{{item.val}}
              </p>
              <p class="w300 p12 text-right invisible-md">{{item.shortVal}}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>
