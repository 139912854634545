<section class="scroller">
  <div class="pt-5 h-100 justify-center align-items-center flex-xs-col px-3">
    <div class="empty flex1"></div>
    <div class="connect_popup info flex2">
      <div class="line-container flex">
        <div class="line flex2"></div>
        <div class="empty flex1"></div>
      </div>
      <div class="info">
        <div class="bottom flex justify-center">
          <div class="flex flex-xs-col align-center">
            <div class="metamask inner-container border-thick pl20 pr20 pb40 pt40" (click)="connectToMetamask()">
              <div class="mb30 flex group-img justify-center"><img src="./assets/media/images/moonbox/Metamask_icon.svg" alt="Metamask" class="img-small"></div>
              <div class="text-center" >
                <p class="p-white-span-primary p24 orbitron">MetaMask</p>
                <p class="description mt10 text-primary">Please connect your Metamask wallet</p>
              </div>
            </div>

            <div class="wallet inner-container border-thick pl20 pr20 pb40 pt40"  (click)="connectToWalletConnect()">
              <div class="mb30 flex group-img justify-center"><img
                  src="./assets/media/images/moonbox/walletconnect-circle-blue.svg" alt="WalletConnect" class="img-small">
              </div>
              <div class="text-center">
                <p class="p-white-span-primary p24 orbitron">WalletConnect</p>
                <p class="description mt10 text-primary">Please scan with WalletConnect to connect</p>
              </div>
            </div>
          </div>
          <div class="empty flex1"></div>
        </div>
      </div>
    </div>
    <div class="empty flex1"></div>
  </div>
</section> 